export const DOC_BASE_URL = "https://docs.spectrocloud.com" as const;
export const DOC_LINK_MAPPING = {
  clusters: "/clusters/",
  "public-cloud": "/clusters/public-cloud/",
  "data-center": "/clusters/data-center/",
  edge: "/clusters/edge/",
  "cluster-management": "/clusters/cluster-management/",
  "deploy-cluster": "/clusters/public-cloud/deploy-k8s-cluster",
  "cluster-profiles": "/cluster-profiles/",
  terraform: "/terraform",
  "imported-clusters": "/clusters/imported-clusters/",
  "node-pool-repave":
    "/clusters/cluster-management/node-pool/#repave-behavior-and-configuration",
  "full-cluster-repave":
    "/clusters/cluster-management/node-pool/#repave-behavior-and-configuration",
  "default-resource-limits": "/user-management/palette-resource-limits/",
  repavement: "/glossary-all/#repavement",
  eks: "/clusters/public-cloud/aws/architecture/#aws-eks-architecture",
  "cluster-management-labels": "/clusters/cluster-management/taints/#labels",
  "cluster-management-taints": "/clusters/cluster-management/taints/#taints",
  "add-helm-registry":
    "/registries-and-packs/helm-charts/#add-a-helm-chart-registry-to-palette",
  "edge-overlay": "/clusters/edge/networking/vxlan-overlay ",
} as const;
